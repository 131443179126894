/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { EmptyStateImage } from './EmptyStateImage';
import { Box } from '@material-ui/core';

/** @public */
export type EmptyStateClassKey = 'root' | 'action' | 'imageContainer';

export const useStyles = makeStyles(
  (theme:any) => ({
    root: {
      // padding: theme.spacing(7),
      padding: '8px',
      borderRadius: '8px',
      zIndex: 0,
      position: 'relative',
      background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      "&:before": {
        content: '""',
        zIndex: -1,
        position: 'absolute',
        borderRadius: '8px',
        top: 2,
        right: 2,
        bottom: 2,
        left: 2,
        backgroundColor: `${theme.palette.grey[1000]}`,
      },
    },
    action: {
      backgroundColor: 'transparent',
      marginTop: theme.spacing(2),
    },
    imageContainer: {
      position: 'relative',
    },
  }),
  { name: 'BackstageEmptyStateCustom' },
);

type Props = {
  title: string;
  description?: string | JSX.Element;
  missing: 'field' | 'info' | 'content' | 'data' | { customImage: JSX.Element };
  action?: JSX.Element;
  isGeneralImageShow: true | boolean;
};

/**
 * Various placeholder views for empty state pages
 *
 * @public
 *
 */
export function EmptyState(props: Props) {
  const { title, description, missing, action } = props;
  const classes = useStyles();
  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent={props.isGeneralImageShow ? 'center' : "flex-start"}
        alignItems="flex-start"
        className={classes.root}
        spacing={2}
      >
        {/* justifyContent=`${props.isGeneralImageShow} ? "flex-start" : 'center'`space-around */}
        <Grid item xs={12} md={ props.isGeneralImageShow ? 6 : 12  }>
          <Grid container direction="column">
            <Grid item xs>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1">{description}</Typography>
            </Grid>
            <Grid item xs className={classes.action}>
              {action}
            </Grid>
          </Grid>
        </Grid>

        {props?.isGeneralImageShow && missing &&
          <Grid item xs={12} md={6} className={classes.imageContainer}>
            {typeof missing === 'string' ? (
              <EmptyStateImage missing={missing} />
            ) : (
              missing.customImage
            )}
          </Grid>
        }
      </Grid>
    </Box>
  );
}
