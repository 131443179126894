/*
 * Copyright 2023 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';
import { LogViewer } from '@internal/core-components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: 8,
    zIndex:0,
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      "&:before": {
        content: '""',
        position: 'absolute',
        background: theme.palette.grey[1100],
        top: 1,
        bottom: 1,
        left: 1,
        right: 1,
        borderRadius: 8,
        zIndex:-1,
      },
  },
}));

/**
 * The text of the event stream
 *
 * @alpha
 */
export const TaskLogStream = (props: { logs: { [k: string]: string[] } }) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <LogViewer
        text={Object.values(props.logs)
          .map(l => l.join('\n'))
          .filter(Boolean)
          .join('\n')}
      />
    </div>
  );
};
