import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const onyxchatPlugin = createPlugin({
  id: 'onyxchat',
  routes: {
    root: rootRouteRef,
  },
});

export const OnyxchatPage = onyxchatPlugin.provide(
  createRoutableExtension({
    name: 'OnyxchatPage',
    component: () =>
      import('./components/Dashboard').then(m => m.Dashboard),
    mountPoint: rootRouteRef,
  }),
);