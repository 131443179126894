/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

export type SignInPageClassKey = 'container' | 'item';

export const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: 0,
    listStyle: 'none',
    position: 'relative',
    borderRadius: 8,
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    "&:before": {
      content: '""',
      position: 'absolute',
      background: theme.palette.grey[1100],
      top: 1,
      bottom: 1,
      left: 1,
      right: 1,
      borderRadius: 8,
  },
},

  item: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '400px',
    margin: 0,
    padding: 0,
  },
}),
  { name: 'BackstageSignInPageCustom' },
);

export const GridItem = ({ children }: { children: JSX.Element }) => {
  const classes = useStyles();

  return (
    <Grid component="li" item classes={classes}>
      {children}
    </Grid>
  );
};
