/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { CodeSnippet, Link } from '@internal/core-components';
import { Entity } from '@backstage/catalog-model';
import { useEntity } from '../../hooks';
import { Grid } from '@material-ui/core';
// import _bannerSidebar from '../../../assets/banner-sidebar.png';
// import _bgBanner from '../../../assets/bg-banner.svg';

/** @public */
export type MissingAnnotationEmptyStateClassKey = 'code';

const useStyles = makeStyles(
  (theme:any) => ({
    annotationWrapper: {
      // borderRadius: '10px',
      padding: theme.spacing(2),
      // background: `url(${_bgBanner})`,
      position: 'relative',
      borderRadius: 8,
      zIndex: 0,
      background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      "&:before": {
        content: '""',
        position: 'absolute',
        background: theme.palette.grey[1100],
        top: 1,
        bottom: 1, 
        zIndex: -1,
        left: 1,
        right: 1,
        borderRadius: 8,
      },
    },
    code: {
      borderRadius: 6,
      margin: theme.spacing(2, 0),
      background:
        theme.palette.type === 'dark' ? theme.palette.primary.dark : theme.palette.common.white,
    },
  }),
  { name: 'BackstageMissingAnnotationEmptyState' },
);

function generateYamlExample(
  annotations: string[],
  entity?: Entity,
): { yamlText: string; lineNumbers: number[] } {
  const kind = entity?.kind || 'Component';
  const name = entity?.metadata.name || 'example';
  const type = entity?.spec?.type || 'website';
  const owner = entity?.spec?.owner || 'user:default/guest';

  const yamlText = `apiVersion: backstage.io/v1alpha1
kind: ${kind}
metadata:
  name: ${name}
  annotations:${annotations.map(ann => `\n    ${ann}: value`).join('')}
spec:
  type: ${type}
  owner: ${owner}`;

  let line = 6; // Line 6 is the line number that annotations are added to.
  const lineNumbers: number[] = [];
  annotations.forEach(() => {
    lineNumbers.push(line);
    line++;
  });

  return {
    yamlText,
    lineNumbers,
  };
}

function generateDescription(annotations: string[], entityKind = 'Component') {
  const isSingular = annotations.length <= 1;
  return (
    <>
      The {isSingular ? 'annotation' : 'annotations'}{' '}
      {annotations
        .map(ann => <code>{ann}</code>)
        .reduce((prev, curr) => (
          <>
            {prev}, {curr}
          </>
        ))}{' '}
      {isSingular ? 'is' : 'are'} missing. You need to add the dfdf{' '}
      {isSingular ? 'annotation' : 'annotations'} to your {entityKind} if you
      want to enable this tool.
    </>
  );
}

/**
 * @public
 * Renders an empty state when an annotation is missing from an entity.
 */
export function MissingAnnotationEmptyState(props: {
  annotation: string | string[];
  readMoreUrl?: string;
}) {
  let entity: Entity | undefined;
  try {
    const entityContext = useEntity();
    entity = entityContext.entity;
  } catch (err) {
    // ignore when entity context doesnt exist
  }

  const { annotation, readMoreUrl } = props;
  const annotations = Array.isArray(annotation) ? annotation : [annotation];
  const url =
    readMoreUrl ||
    'https://backstage.io/docs/features/software-catalog/well-known-annotations';
  const classes = useStyles();

  const entityKind = entity?.kind || 'Component';
  const { yamlText, lineNumbers } = generateYamlExample(annotations, entity);
  return (
    <Box >
      <Grid item container className={classes.annotationWrapper} >

        <Grid item md={6} spacing={5}>
          <Typography variant="h5">Missing Annotations</Typography>
          <Typography variant="h6">{generateDescription(annotations, entityKind)}</Typography>


          <Box className={classes.code}>
            <CodeSnippet
              text={yamlText}
              language="yaml"
              showLineNumbers
              highlightedNumbers={lineNumbers}
              customStyle={{ background: 'inherit', fontSize: '115%' }}
            />
          </Box>
          <Button color="primary" component={Link} to={url}>
            Read more
          </Button>
        </Grid>
        <Grid item md={6} spacing={5}>
          {/* <img src={_bannerSidebar} alt="_bannerSidebar" /> */}
        </Grid>

      </Grid>
    </Box>
  );
}
