import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const diagramGraphRepositoryPlugin = createPlugin({
  id: 'diagram-graph-repository',
  routes: {
    root: rootRouteRef,
  },
});

export const DiagramGraphRepositoryPage = diagramGraphRepositoryPlugin.provide(
  createRoutableExtension({
    name: 'DiagramGraphRepositoryPage',
    component: () =>
      import('./components/LayoutDiagram').then(m => m.LayoutDiagram),
    mountPoint: rootRouteRef,
  }),
);
