/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { AlphaEntity } from '@backstage/catalog-model/alpha';
import {
  Box,
  DialogContentText,
  IconButton,
  List,
  ListItem,
  Tab,
  ListItemIcon,
  ListItemSecondaryAction,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { EntityRefLink } from '../../EntityRefLink';
import {
  Container,
  HelpIconC,
  KeyValueListItem,
  ListItemText,
  ListSubheader,
} from './common';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { CopyTextButton } from '@internal/core-components';
import CloseIcon from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'column',
  },
});

export function OverviewPage(props: { entity: AlphaEntity, onCloseModal: any }) {
  const classes = useStyles();
  const {
    apiVersion,
    kind,
    metadata,
    spec,
    relations = [],
    status = {},
  } = props.entity;

  const groupedRelations = groupBy(
    sortBy(relations, r => r.targetRef),
    'type',
  );

  const entityRef = stringifyEntityRef(props.entity);
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log(event);
    setValue(newValue);
  };
  return (
    <>
      <DialogContentText variant="h4">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          Overview
          <IconButton
            aria-label="close"
            onClick={props?.onCloseModal()}
          >
            <CloseIcon />
          </IconButton>
        </Box>

      </DialogContentText>
      <div className={classes.root}>
        <Box sx={{ width: '100%' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab className='btn-onex-primary' label="Identity" value="1" />
                <Tab label="Metadata" value="2" />
                <Tab label="Relations" value="3" />
              </TabList>
            </Box>

            <TabPanel value="1" sx={{ p: 0 }}>
              <Container title="Identity" >
                <List dense>
                  <ListItem>
                    <ListItemText primary="apiVersion" secondary={apiVersion} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="kind" secondary={kind} />
                  </ListItem>
                  {spec?.type && (
                    <ListItem>
                      <ListItemText
                        primary="spec.type"
                        secondary={spec.type?.toString()}
                      />
                    </ListItem>
                  )}
                  {metadata.uid && (
                    <ListItem>
                      <ListItemText primary="uid" secondary={metadata.uid} />
                      <ListItemSecondaryAction>
                        <CopyTextButton text={metadata.uid} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                  {metadata.etag && (
                    <ListItem>
                      <ListItemText primary="etag" secondary={metadata.etag} />
                      <ListItemSecondaryAction>
                        <CopyTextButton text={metadata.etag} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemText primary="entityRef" secondary={entityRef} />
                    <ListItemSecondaryAction>
                      <CopyTextButton text={entityRef} />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Container>
            </TabPanel>

            <TabPanel value="2" sx={{ p: 0 }}>
              <Container title="">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6">Metadata</Typography>
                  <Button variant='outlined' color='primary' >
                    <Link to="https://backstage.io/docs/features/software-catalog/well-known-annotations">
                      Annotations
                    </Link>
                    <HelpIconC to="https://backstage.io/docs/features/software-catalog/well-known-annotations" />
                  </Button>
                </Box>

                {!!Object.keys(metadata.annotations || {}).length && (
                  <List>
                    {Object.entries(metadata.annotations!).map(entry => (
                      <KeyValueListItem key={entry[0]} indent entry={entry} />
                    ))}
                  </List>
                )}
                {!!Object.keys(metadata.labels || {}).length && (
                  <List dense subheader={<ListSubheader>Labels</ListSubheader>}>
                    {Object.entries(metadata.labels!).map(entry => (
                      <KeyValueListItem key={entry[0]} indent entry={entry} />
                    ))}
                  </List>
                )}
                {!!metadata.tags?.length && (
                  <List dense subheader={<ListSubheader>Tags</ListSubheader>}>
                    {metadata.tags.map((tag, index) => (
                      <ListItem key={`${tag}-${index}`}>
                        <ListItemIcon />
                        <ListItemText primary={tag} />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Container>
            </TabPanel>

            <TabPanel value="3" sx={{ p: 0 }}>
              {!!relations.length && (
                <Container title="" >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Relations</Typography>
                    <HelpIconC to="https://backstage.io/docs/features/software-catalog/well-known-relations" />
                  </Box>

                  {Object.entries(groupedRelations).map(
                    ([type, groupRelations], index) => (
                      <div key={index}>
                        <List dense subheader={<ListSubheader>{type.replace('By', '')}</ListSubheader>}>
                          {groupRelations.map(group => (
                            <ListItem key={group.targetRef}>
                              <ListItemText
                                primary={
                                  <EntityRefLink entityRef={group.targetRef} hideOutline={true} />
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    ),
                  )}
                </Container>
              )}

              {!!status.items?.length && (
                <Container title=""                 >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Status</Typography>
                    <HelpIconC to="https://backstage.io/docs/features/software-catalog/well-known-statuses" />
                  </Box>
                  {status.items.map((item, index) => (
                    <div key={index}>
                      <Typography>
                        {item.level}: {item.type}
                      </Typography>
                      <Box ml={2}>{item.message}</Box>
                    </div>
                  ))}
                </Container>
              )}</TabPanel>
          </TabContext>
        </Box>
      </div>
    </>
  );
}
