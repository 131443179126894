import React, { PropsWithChildren } from 'react';
import { makeStyles, Theme,  Box } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { SidebarSearchModal } from '@internal/plugin-search-custom';

import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@internal/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@internal/plugin-profile-settings';

const useSidebarLogoStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '210px',
    height: 2 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: 8,
  },
  link: {
    width: '210px',
  },
  chatBtnWrapper: {
    position: 'absolute',
    right: '-18px',
    height: '50px',
    width: '50px',
    bottom: '56px',
  },
  chatBtn:
  {
    position: 'fixed',
    background: `${theme.palette.secondary.dark}`,
    borderRadius: '50%',
    boxShadow: `0px 0px 3px 2px ${theme.palette.secondary.dark}`,
    animation: "$GlowChatBtn 2s linear infinite",
  },
  "@keyframes GlowChatBtn": {
    "0%": {
      boxShadow: `0px 0px 4px 3px ${theme.palette.secondary.dark}`
    },
    "50%": {
      boxShadow: `0px 0px 5px 6px ${theme.palette.secondary.dark}`
    },
    "100%": {
      boxShadow: `0px 0px 4px 3px ${theme.palette.secondary.dark}`
    }
  }
}
)
);



const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} style={{ margin: `0px ${isOpen ? '24px' : '0px'}`}} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};
export const Root = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <SidebarPage>
        <Sidebar>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SidebarLogo />
          </Box>

          <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
            <SidebarSearchModal />
          </SidebarGroup>
          <SidebarDivider />
          <SidebarGroup label="Menu" icon={<MenuIcon />}>
            <SidebarItem icon={HomeIcon} to="catalog" text="Home" />
            <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
            <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
            <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
            <SidebarDivider />
            <SidebarScrollWrapper>
              <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
            </SidebarScrollWrapper>
          </SidebarGroup>
          <SidebarSpace />

          <SidebarDivider />
          <SidebarGroup label="Settings" icon={<UserSettingsSignInAvatar />} to="/profile-settings">
            <SidebarSettings />
          </SidebarGroup>
        </Sidebar>
        {children}
      </SidebarPage >
    </Box>)
};
