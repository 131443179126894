/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { cloneElement, Fragment, useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { searchApiRef, useSearch } from '@internal/plugin-search-react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AllIcon from '@material-ui/icons/FontDownload';
import useAsync from 'react-use/lib/useAsync';

const useStyles = makeStyles((theme:any) => ({
  card: {
    backgroundColor: 'rgba(0, 0, 0, .11)',
  },
  cardContentWapper: {
    position: 'relative',
    margin:'16px',
    borderRadius: 8,
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    "&:before": {
      content: '""',
      position: 'absolute',
      background: theme.palette.grey[1100],
      top: 1,
      bottom: 1,
      left: 1,
      right: 1,
      borderRadius: 8,
    },

  },
  cardContent: {
    padding:1,
  },
  icon: {
    color: theme.palette.text.primary,
  },
  list: {
    width: '100%',
  },
  listItemIcon: {
    width: '24px',
    height: '24px',
  },
  accordion: {
  },
  accordionSummary: {
    minHeight: 'auto',
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
  },
  accordionSummaryContent: {
    margin: theme.spacing(2, 0),
    '&.Mui-expanded': {
      margin: theme.spacing(2, 0),
    },
  },
  accordionDetails: {
    padding: theme.spacing(0, 0, 1),
  },
}));

/**
 * @public
 */
export type SearchTypeAccordionProps = {
  name: string;
  types: Array<{
    value: string;
    name: string;
    icon: JSX.Element;
  }>;
  defaultValue?: string;
  showCounts?: boolean;
};

export const SearchTypeAccordion = (props: SearchTypeAccordionProps) => {
  const classes = useStyles();
  const { filters, setPageCursor, setTypes, term, types } = useSearch();
  const searchApi = useApi(searchApiRef);
  const [expanded, setExpanded] = useState(true);
  const { defaultValue, name, showCounts, types: givenTypes } = props;

  const toggleExpanded = () => setExpanded(prevState => !prevState);
  const handleClick = (type: string) => {
    return () => {
      setTypes(type !== '' ? [type] : []);
      setPageCursor(undefined);
      setExpanded(false);
    };
  };

  // Handle any provided defaultValue
  useEffect(() => {
    if (defaultValue) {
      setTypes([defaultValue]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const definedTypes = [
    {
      value: '',
      name: 'All',
      icon: <AllIcon />,
    },
    ...givenTypes,
  ];
  const selected = types[0] || '';

  const { value: resultCounts } = useAsync(async () => {
    if (!showCounts) {
      return {};
    }

    const counts = await Promise.all(
      definedTypes
        .map(t => t.value)
        .map(async type => {
          const { numberOfResults } = await searchApi.query({
            term,
            types: type ? [type] : [],
            filters:
              types.includes(type) || (!types.length && !type) ? filters : {},
            pageLimit: 0,
          });

          return [
            type,
            numberOfResults !== undefined
              ? `${numberOfResults >= 10000 ? `>10000` : numberOfResults
              } results`
              : ' -- ',
          ];
        }),
    );

    return Object.fromEntries(counts);
  }, [filters, showCounts, term, types]);

  return (
    <Card className={classes.card}>
      <CardHeader title={name} titleTypographyProps={{ variant: 'overline' }} />
        <Box className={classes.cardContentWapper}>
      <CardContent className={classes.cardContent}>
          <Accordion
            className={classes.accordion}
            expanded={expanded}
            onChange={toggleExpanded}
          >
            <AccordionSummary
              classes={{
                root: classes.accordionSummary,
                content: classes.accordionSummaryContent,
              }}
              expandIcon={<ExpandMoreIcon className={classes.icon} />}
              IconButtonProps={{ size: 'small' }}
            >
              {expanded
                ? 'Collapse'
                : definedTypes.filter(t => t.value === selected)[0]!.name}
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
              <List
                className={classes.list}
                component="nav"
                aria-label="filter by type"
                disablePadding
                dense
              >
                {definedTypes.map(type => (
                  <Fragment key={type.value}>
                    <Divider />
                    <ListItem
                      selected={
                        types[0] === type.value ||
                        (types.length === 0 && type.value === '')
                      }
                      onClick={handleClick(type.value)}
                      button
                    >
                      <ListItemIcon>
                        {cloneElement(type.icon, {
                          className: classes.listItemIcon,
                        })}
                      </ListItemIcon>
                      <ListItemText
                        primary={type.name}
                        secondary={resultCounts && resultCounts[type.value]}
                      />
                    </ListItem>
                  </Fragment>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
      </CardContent>
        </Box>
    </Card>
  );
};
