import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import {
  apiDocsPlugin,
  ApiExplorerPage,
} from '@internal/plugin-api-docs-custom';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@internal/plugin-catalog-custom';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@internal/plugin-catalog-import-custom';
import { ScaffolderPage, scaffolderPlugin } from '@internal/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@internal/plugin-search-custom';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@internal/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@internal/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@internal/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

import { UnifiedThemeProvider } from '@backstage/theme';
import { CssBaseline } from '@material-ui/core';
import { GlobalStyles } from '@mui/material';
import { ideaToLifeTheme } from './assets/theme/ideaToLifeTheme';
import { OnyxchatPage } from '@internal/plugin-onyxchat';
import { ProfileSettingsPage } from '@internal/plugin-profile-settings';
import { DiagramGraphRepositoryPage } from '@internal/backstage-plugin-diagram-graph-repository';
import './assets/theme/style.css';
import { githubAuthApiRef, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { SignInPage } from './components/SignInPage';


const app = createApp({
  apis,
  components: {
    //TODO: to be replaced by the actual sign up page
    NotFoundErrorPage: () => null,
    BootErrorPage: () => null,
    Progress: () => null,
    SignInPage: props => (
      <SignInPage
        {...props}
        providers={[
          {
            id: 'github-auth-provider',
            title: 'GitHub',
            message: 'Sign in using GitHub',
            apiRef: githubAuthApiRef,
          },
          {
            id: 'microsoft-auth-provider',
            title: 'Microsoft',
            message: 'Sign in using Microsoft',
            apiRef: microsoftAuthApiRef,
          },
        ]}
      />
    ),
    ErrorBoundaryFallback: () => null,
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'I2L',
      title: 'I2L',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={ideaToLifeTheme}>
          <CssBaseline />
          <GlobalStyles
            styles={{
              body: { backgroundColor: '#050D21!important' },

              '*::-webkit-scrollbar': {
                width: '4px',
                height: '4px',
              },
              '*::-webkit-scrollbar-track': {
                borderRadius: '8px',
                '-webkit-box-shadow': `inset 0 0 5px #602080`,
              },
              '*::-webkit-scrollbar-thumb': {
                border: 'none',
                background: `linear-gradient(to right, #602080, #8EE7F5)`,
              },
            }}
          />
          {children}
        </UnifiedThemeProvider>
      ),
    },
  ],
});

export default app.createRoot(
  <>
    <AlertDisplay
      transientTimeoutMs={2500}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>
        <FlatRoutes>
          <Route path="/" element={<Navigate to="catalog" />} />
          <Route path="/sign-in" element={<CatalogIndexPage />} />
          <Route path="/catalog" element={<CatalogIndexPage />} />
          <Route
            path="/catalog/:namespace/:kind/:name"
            element={<CatalogEntityPage />}
          >
            {entityPage}
          </Route>
          <Route path="/docs" element={<TechDocsIndexPage />} />
          <Route
            path="/docs/:namespace/:kind/:name/*"
            element={<TechDocsReaderPage />}
          >
            <TechDocsAddons>
              <ReportIssue />
            </TechDocsAddons>
          </Route>
          <Route path="/create" element={<ScaffolderPage />} />
          <Route path="/api-docs" element={<ApiExplorerPage />} />
          <Route
            path="/tech-radar"
            element={<TechRadarPage width={1500} height={800} />}
          />
          <Route
            path="/catalog-import"
            element={
              <RequirePermission permission={catalogEntityCreatePermission}>
                <CatalogImportPage />
              </RequirePermission>
            }
          />
          <Route path="/search" element={<SearchPage />}>
            {searchPage}
          </Route>
          <Route path="/catalog-graph" element={<CatalogGraphPage />} />
          <Route path="/onyxchat" element={<OnyxchatPage />} />
          <Route path="/profile-settings" element={<ProfileSettingsPage />} />
          <Route path="/diagram-graph-repository" element={<DiagramGraphRepositoryPage />} />
        </FlatRoutes>
      </Root>
    </AppRouter>
  </>,
);
