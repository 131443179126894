import React, { Fragment } from 'react';
import onnexLogo from '../../assets/logo/OneX-Emblem.svg'
const LogoFull = () => {
  return <Fragment>

    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
      <img height={46} src={onnexLogo} />
    </div>
  </Fragment>
};

export default LogoFull;
