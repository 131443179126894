/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  BackstageIdentityResponse,
  configApiRef,
  SignInPageProps,
  useApi,
} from '@backstage/core-plugin-api';
import { UserIdentity } from './UserIdentity';
import { Button, Grid, Typography, Container, CssBaseline, Paper, Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useMountEffect } from '@react-hookz/web';
import { Progress, Content, Header, InfoCard, Page } from '@internal/core-components';

import { getSignInProviders, useSignInProviders } from './providers';
import { GridItem, useStyles } from './styles';
import { IdentityProviders, SignInProviderConfig } from './types';

import {  Routes, Route } from 'react-router-dom';
import { LoginPage } from '@internal/plugin-login';
import { SignupPage } from '@internal/plugin-signup';
import { ForgotPage } from '@internal/plugin-forgot';

type MultiSignInPageProps = SignInPageProps & {
  providers: IdentityProviders;
  title?: string;
  align?: 'center' | 'left';
};

type SingleSignInPageProps = SignInPageProps & {
  provider: SignInProviderConfig;
  auto?: boolean;
};

export type Props = MultiSignInPageProps | SingleSignInPageProps;


export const MultiSignInPage = ({
  onSignInSuccess,
  providers = [],
}: MultiSignInPageProps) => {
  const configApi = useApi(configApiRef);
  const classes = useStyles();
  const signInProviders = getSignInProviders(providers);
  const [loading, providerElements] = useSignInProviders(signInProviders, onSignInSuccess);

  if (loading) {
    return <Progress />;
  }
 
  const aws_Provider = "aws-cognito-auth";
  return (
    <>
      <Header title={'Welcome to ' + configApi.getString('app.title')} />
      <Container maxWidth="md" >
        <CssBaseline />
        <Box sx={{ pt: 5 }}>
          <Routes>
            <Route path="/" element={<LoginPage provider={aws_Provider} providers={providers} onSignInSuccess={onSignInSuccess} />} />
            <Route index path='/login' element={<LoginPage provider={aws_Provider} providers={providers} onSignInSuccess={onSignInSuccess} />} />
            <Route path="signup" element={<SignupPage provider={aws_Provider} />} />
            <Route path="forgot" element={<ForgotPage provider={aws_Provider} />} />
            <Route path="*" element={<LoginPage providers={providers} provider={aws_Provider} onSignInSuccess={onSignInSuccess} />} />
          </Routes>
        </Box>



        <Container className={classes.container} maxWidth="xs" component={Paper} elevation={6} >
          <Box sx={{ py: 2, mt:2, display:'flex', justifyContent:'space-around' }}>
            {providerElements}
          </Box>
        </Container>
      </Container>
    </>
  );
};

export const SingleSignInPage = ({
  provider,
  auto,
  onSignInSuccess,
}: SingleSignInPageProps) => {
  const classes = useStyles();
  const authApi = useApi(provider.apiRef);
  const [error, setError] = useState<Error>();

  // The SignIn component takes some time to decide whether the user is logged-in or not.
  // showLoginPage is used to prevent a glitch-like experience where the sign-in page is
  // displayed for a split second when the user is already logged-in.
  const [showLoginPage, setShowLoginPage] = useState<boolean>(false);

  type LoginOpts = { checkExisting?: boolean; showPopup?: boolean };
  const login = async ({ checkExisting, showPopup }: LoginOpts) => {
    try {
      let identityResponse: BackstageIdentityResponse | undefined;
      if (checkExisting) {
        // Do an initial check if any logged-in session exists
        identityResponse = await authApi.getBackstageIdentity({
          optional: true,
        });
      }

      // If no session exists, show the sign-in page
      if (!identityResponse && (showPopup || auto)) {
        // Unless auto is set to true, this step should not happen.
        // When user intentionally clicks the Sign In button, autoShowPopup is set to true
        setShowLoginPage(true);
        identityResponse = await authApi.getBackstageIdentity({
          instantPopup: true,
        });
        if (!identityResponse) {
          throw new Error(
            `The ${provider.title} provider is not configured to support sign-in`,
          );
        }
      }

      if (!identityResponse) {
        setShowLoginPage(true);
        return;
      }

      const profile = await authApi.getProfile();
      onSignInSuccess(
        UserIdentity.create({
          identity: identityResponse.identity,
          authApi,
          profile,
        }),
      );
    } catch (err: any) {
      // User closed the sign-in modal
      setError(err);
      setShowLoginPage(true);
    }
  };

  useMountEffect(() => login({ checkExisting: true }));

  return showLoginPage ? (
    <Page themeId="home">
      <Content>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          component="ul"
          classes={classes}
        >
          <GridItem>
            <InfoCard
              actions={
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    login({ showPopup: true });
                  }}
                >
                  {provider.title}
                </Button>
              }
            >
              {error && error.name !== 'PopupRejectedError' && (
                <Typography variant="body1" color="error">
                  {error.message}
                </Typography>
              )}
            </InfoCard>
          </GridItem>
        </Grid>
      </Content>
    </Page>
  ) : (
    <Progress />
  );
};


export function SignInPage(props: Props) {
  if ('provider' in props) {
    return <SingleSignInPage {...props} />;
  }

  return <MultiSignInPage {...props} />;
}
