import React  from 'react';
import {TaskLogStream} from '@internal/plugin-scaffolder-react/alpha';

export const MonitorLogs = () => {
    const taskStream = {
        "logs": {
            "fetch-base": [
                "2024-05-17T06:07:33.910Z Beginning step Fetch Base",
                "2024-05-17T06:07:33.923Z \u001b[32minfo\u001b[39m: Fetching template content from remote URL",
                "2024-05-17T06:07:33.995Z \u001b[32minfo\u001b[39m: Listing files and directories in template",
                "2024-05-17T06:07:34.005Z \u001b[32minfo\u001b[39m: Processing 165 template files/directories with input values {\"name\":\"dsf\"}",
                "2024-05-17T06:07:34.071Z \u001b[32minfo\u001b[39m: Writing file angular.json to template output path with mode 33206.",
                "2024-05-17T06:07:34.093Z \u001b[32minfo\u001b[39m: Writing file catalog-info.yaml to template output path with mode 33206.",
                "2024-05-17T06:07:34.104Z \u001b[32minfo\u001b[39m: Writing file ngsw-config.json to template output path with mode 33206.",
                "2024-05-17T06:07:34.129Z \u001b[32minfo\u001b[39m: Writing file package.json to template output path with mode 33206.",
                "2024-05-17T06:07:34.138Z \u001b[32minfo\u001b[39m: Writing file README.md to template output path with mode 33206.",
                "2024-05-17T06:07:34.142Z \u001b[32minfo\u001b[39m: Writing directory src/ to template output path.",
                "2024-05-17T06:07:34.148Z \u001b[32minfo\u001b[39m: Writing file tsconfig.app.json to template output path with mode 33206.",
                "2024-05-17T06:07:34.155Z \u001b[32minfo\u001b[39m: Writing file tsconfig.json to template output path with mode 33206.",
                "2024-05-17T06:07:34.163Z \u001b[32minfo\u001b[39m: Writing file tsconfig.spec.json to template output path with mode 33206.",
                "2024-05-17T06:07:34.170Z \u001b[32minfo\u001b[39m: Writing directory src/app/ to template output path.",
                "2024-05-17T06:07:34.172Z \u001b[32minfo\u001b[39m: Writing directory src/assets/ to template output path.",
                "2024-05-17T06:07:34.188Z \u001b[32minfo\u001b[39m: Writing file src/env.d.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.193Z \u001b[32minfo\u001b[39m: Writing directory src/environments/ to template output path.",
                "2024-05-17T06:07:34.206Z \u001b[32minfo\u001b[39m: Copying file binary or symbolic link at src/favicon.ico, to template output path.",
                "2024-05-17T06:07:34.213Z \u001b[32minfo\u001b[39m: Writing file src/index.html to template output path with mode 33206.",
                "2024-05-17T06:07:34.220Z \u001b[32minfo\u001b[39m: Writing file src/main.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.227Z \u001b[32minfo\u001b[39m: Writing file src/styles.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.231Z \u001b[32minfo\u001b[39m: Writing directory src/theme/ to template output path.",
                "2024-05-17T06:07:34.236Z \u001b[32minfo\u001b[39m: Writing file src/assets/.gitkeep to template output path with mode 33206.",
                "2024-05-17T06:07:34.240Z \u001b[32minfo\u001b[39m: Writing directory src/assets/fonts/ to template output path.",
                "2024-05-17T06:07:34.245Z \u001b[32minfo\u001b[39m: Writing file src/app/app.component.html to template output path with mode 33206.",
                "2024-05-17T06:07:34.252Z \u001b[32minfo\u001b[39m: Writing file src/app/app.component.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.258Z \u001b[32minfo\u001b[39m: Writing file src/app/app.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.274Z \u001b[32minfo\u001b[39m: Writing file src/app/app.config.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.282Z \u001b[32minfo\u001b[39m: Writing file src/app/app.routes.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.287Z \u001b[32minfo\u001b[39m: Writing directory src/app/auth/ to template output path.",
                "2024-05-17T06:07:34.289Z \u001b[32minfo\u001b[39m: Writing directory src/app/core/ to template output path.",
                "2024-05-17T06:07:34.291Z \u001b[32minfo\u001b[39m: Writing directory src/app/layout/ to template output path.",
                "2024-05-17T06:07:34.296Z \u001b[32minfo\u001b[39m: Writing file src/environments/environment.development.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.303Z \u001b[32minfo\u001b[39m: Writing file src/environments/environment.prod.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.309Z \u001b[32minfo\u001b[39m: Writing file src/environments/environment.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.316Z \u001b[32minfo\u001b[39m: Writing file src/environments/environment.uat.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.320Z \u001b[32minfo\u001b[39m: Writing directory src/theme/abstract/ to template output path.",
                "2024-05-17T06:07:34.322Z \u001b[32minfo\u001b[39m: Writing directory src/theme/base/ to template output path.",
                "2024-05-17T06:07:34.324Z \u001b[32minfo\u001b[39m: Writing directory src/theme/bootstripe-config/ to template output path.",
                "2024-05-17T06:07:34.326Z \u001b[32minfo\u001b[39m: Writing directory src/theme/components/ to template output path.",
                "2024-05-17T06:07:34.329Z \u001b[32minfo\u001b[39m: Writing directory src/theme/font/ to template output path.",
                "2024-05-17T06:07:34.331Z \u001b[32minfo\u001b[39m: Writing directory src/theme/partials/ to template output path.",
                "2024-05-17T06:07:34.337Z \u001b[32minfo\u001b[39m: Writing file src/theme/theme.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.341Z \u001b[32minfo\u001b[39m: Writing directory src/assets/fonts/cairo/ to template output path.",
                "2024-05-17T06:07:34.343Z \u001b[32minfo\u001b[39m: Writing directory src/assets/fonts/opensans/ to template output path.",
                "2024-05-17T06:07:34.349Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/auth.routing.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.355Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/authLayout.component.css to template output path with mode 33206.",
                "2024-05-17T06:07:34.360Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/authLayout.component.html to template output path with mode 33206.",
                "2024-05-17T06:07:34.368Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/authLayout.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.380Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/authLayout.module.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.385Z \u001b[32minfo\u001b[39m: Writing directory src/app/auth/core/ to template output path.",
                "2024-05-17T06:07:34.387Z \u001b[32minfo\u001b[39m: Writing directory src/app/auth/forgot-password/ to template output path.",
                "2024-05-17T06:07:34.390Z \u001b[32minfo\u001b[39m: Writing directory src/app/auth/reset-password/ to template output path.",
                "2024-05-17T06:07:34.392Z \u001b[32minfo\u001b[39m: Writing directory src/app/auth/signin/ to template output path.",
                "2024-05-17T06:07:34.394Z \u001b[32minfo\u001b[39m: Writing directory src/app/auth/signup/ to template output path.",
                "2024-05-17T06:07:34.399Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/dashboard.component.html to template output path with mode 33206.",
                "2024-05-17T06:07:34.406Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/dashboard.component.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.413Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/dashboard.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.420Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/dashboard.module.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.428Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/dashboard.routing.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.433Z \u001b[32minfo\u001b[39m: Writing directory src/app/layout/featureOne/ to template output path.",
                "2024-05-17T06:07:34.437Z \u001b[32minfo\u001b[39m: Writing directory src/app/layout/featureTwo/ to template output path.",
                "2024-05-17T06:07:34.439Z \u001b[32minfo\u001b[39m: Writing directory src/app/layout/header/ to template output path.",
                "2024-05-17T06:07:34.441Z \u001b[32minfo\u001b[39m: Writing directory src/app/layout/sideBar/ to template output path.",
                "2024-05-17T06:07:34.443Z \u001b[32minfo\u001b[39m: Writing directory src/app/core/base/ to template output path.",
                "2024-05-17T06:07:34.445Z \u001b[32minfo\u001b[39m: Writing directory src/app/core/constants/ to template output path.",
                "2024-05-17T06:07:34.449Z \u001b[32minfo\u001b[39m: Writing directory src/app/core/interceptors/ to template output path.",
                "2024-05-17T06:07:34.453Z \u001b[32minfo\u001b[39m: Writing directory src/app/core/services/ to template output path.",
                "2024-05-17T06:07:34.456Z \u001b[32minfo\u001b[39m: Writing directory src/app/core/shared/ to template output path.",
                "2024-05-17T06:07:34.462Z \u001b[32minfo\u001b[39m: Writing file src/theme/abstract/_mixins.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.472Z \u001b[32minfo\u001b[39m: Writing file src/theme/abstract/_variable.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.479Z \u001b[32minfo\u001b[39m: Writing file src/theme/base/_base.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.488Z \u001b[32minfo\u001b[39m: Writing file src/theme/base/_typography.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.496Z \u001b[32minfo\u001b[39m: Writing file src/theme/base/_utilities.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.521Z \u001b[32minfo\u001b[39m: Writing file src/theme/bootstripe-config/_bootstripe-config.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.539Z \u001b[32minfo\u001b[39m: Writing file src/theme/bootstripe-config/_bootstripe-variable.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.570Z \u001b[32minfo\u001b[39m: Writing file src/theme/components/intlTelInput.css to template output path with mode 33206.",
                "2024-05-17T06:07:34.590Z \u001b[32minfo\u001b[39m: Writing file src/theme/components/_accordion.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.597Z \u001b[32minfo\u001b[39m: Writing file src/theme/components/_buttons.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.605Z \u001b[32minfo\u001b[39m: Writing file src/theme/components/_checkbox.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.612Z \u001b[32minfo\u001b[39m: Writing file src/theme/components/_inputs.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.619Z \u001b[32minfo\u001b[39m: Writing file src/theme/components/_model.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.625Z \u001b[32minfo\u001b[39m: Writing file src/theme/components/_ng-select.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.633Z \u001b[32minfo\u001b[39m: Writing file src/theme/components/_ngx-toastr.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.644Z \u001b[32minfo\u001b[39m: Writing file src/theme/components/_radio.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.652Z \u001b[32minfo\u001b[39m: Writing file src/theme/components/_svg-sprite-icon.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.658Z \u001b[32minfo\u001b[39m: Writing file src/theme/components/_tabs.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.673Z \u001b[32minfo\u001b[39m: Writing file src/theme/font/_font-face.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.684Z \u001b[32minfo\u001b[39m: Writing file src/theme/partials/_ckeditor-html.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.691Z \u001b[32minfo\u001b[39m: Writing file src/theme/partials/_date-picker.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.698Z \u001b[32minfo\u001b[39m: Writing file src/theme/partials/_form.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.710Z \u001b[32minfo\u001b[39m: Writing file src/theme/partials/_newsletter.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.717Z \u001b[32minfo\u001b[39m: Writing file src/theme/partials/_page-header.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.723Z \u001b[32minfo\u001b[39m: Writing file src/theme/partials/_password-field.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.729Z \u001b[32minfo\u001b[39m: Writing file src/theme/partials/_table-listing.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.736Z \u001b[32minfo\u001b[39m: Writing file src/theme/partials/_view-toggle.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.746Z \u001b[32minfo\u001b[39m: Copying file binary or symbolic link at src/assets/fonts/cairo/SLXGc1nY6HkvalIhTps.woff2, to template output path.",
                "2024-05-17T06:07:34.760Z \u001b[32minfo\u001b[39m: Copying file binary or symbolic link at src/assets/fonts/cairo/SLXGc1nY6HkvalIkTpu0xg.woff2, to template output path.",
                "2024-05-17T06:07:34.773Z \u001b[32minfo\u001b[39m: Copying file binary or symbolic link at src/assets/fonts/cairo/SLXGc1nY6HkvalIvTpu0xg.woff2, to template output path.",
                "2024-05-17T06:07:34.787Z \u001b[32minfo\u001b[39m: Copying file binary or symbolic link at src/assets/fonts/opensans/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2, to template output path.",
                "2024-05-17T06:07:34.803Z \u001b[32minfo\u001b[39m: Copying file binary or symbolic link at src/assets/fonts/opensans/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2, to template output path.",
                "2024-05-17T06:07:34.825Z \u001b[32minfo\u001b[39m: Copying file binary or symbolic link at src/assets/fonts/opensans/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2, to template output path.",
                "2024-05-17T06:07:34.848Z \u001b[32minfo\u001b[39m: Copying file binary or symbolic link at src/assets/fonts/opensans/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2, to template output path.",
                "2024-05-17T06:07:34.863Z \u001b[32minfo\u001b[39m: Copying file binary or symbolic link at src/assets/fonts/opensans/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2, to template output path.",
                "2024-05-17T06:07:34.872Z \u001b[32minfo\u001b[39m: Copying file binary or symbolic link at src/assets/fonts/opensans/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2, to template output path.",
                "2024-05-17T06:07:34.889Z \u001b[32minfo\u001b[39m: Copying file binary or symbolic link at src/assets/fonts/opensans/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2, to template output path.",
                "2024-05-17T06:07:34.906Z \u001b[32minfo\u001b[39m: Copying file binary or symbolic link at src/assets/fonts/opensans/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2, to template output path.",
                "2024-05-17T06:07:34.911Z \u001b[32minfo\u001b[39m: Writing directory src/app/auth/core/services/ to template output path.",
                "2024-05-17T06:07:34.917Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/signup/signup.component.html to template output path with mode 33206.",
                "2024-05-17T06:07:34.924Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/signup/signup.component.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.937Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/signup/signup.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.944Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/forgot-password/forgot-password.component.html to template output path with mode 33206.",
                "2024-05-17T06:07:34.950Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/forgot-password/forgot-password.component.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.957Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/forgot-password/forgot-password.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.964Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/signin/signin.component.html to template output path with mode 33206.",
                "2024-05-17T06:07:34.970Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/signin/signin.component.scss to template output path with mode 33206.",
                "2024-05-17T06:07:34.984Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/signin/signin.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:34.991Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/reset-password/reset-password.component.html to template output path with mode 33206.",
                "2024-05-17T06:07:34.997Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/reset-password/reset-password.component.scss to template output path with mode 33206.",
                "2024-05-17T06:07:35.005Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/reset-password/reset-password.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.011Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/featureTwo/featureTwo.component.css to template output path with mode 33206.",
                "2024-05-17T06:07:35.026Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/featureTwo/featureTwo.component.html to template output path with mode 33206.",
                "2024-05-17T06:07:35.033Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/featureTwo/featureTwo.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.045Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/featureTwo/featureTwo.module.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.050Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/featureOne/featureOne.component.css to template output path with mode 33206.",
                "2024-05-17T06:07:35.057Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/featureOne/featureOne.component.html to template output path with mode 33206.",
                "2024-05-17T06:07:35.063Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/featureOne/featureOne.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.071Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/featureOne/featureOne.module.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.082Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/featureOne/featureOne.routing.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.086Z \u001b[32minfo\u001b[39m: Writing directory src/app/layout/featureOne/featureOneSub/ to template output path.",
                "2024-05-17T06:07:35.091Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/sideBar/sideBar.component.html to template output path with mode 33206.",
                "2024-05-17T06:07:35.096Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/sideBar/sideBar.component.scss to template output path with mode 33206.",
                "2024-05-17T06:07:35.103Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/sideBar/sideBar.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.110Z \u001b[32minfo\u001b[39m: Writing file src/app/core/base/base.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.117Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/header/header.component.html to template output path with mode 33206.",
                "2024-05-17T06:07:35.123Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/header/header.component.scss to template output path with mode 33206.",
                "2024-05-17T06:07:35.128Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/header/header.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.136Z \u001b[32minfo\u001b[39m: Writing file src/app/core/constants/apis-list.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.150Z \u001b[32minfo\u001b[39m: Writing file src/app/core/constants/constant-list.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.157Z \u001b[32minfo\u001b[39m: Writing file src/app/core/constants/routes.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.163Z \u001b[32minfo\u001b[39m: Writing file src/app/core/interceptors/jwt.interceptor.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.170Z \u001b[32minfo\u001b[39m: Writing file src/app/core/interceptors/timer.interceptor.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.174Z \u001b[32minfo\u001b[39m: Writing directory src/app/core/shared/dialogs/ to template output path.",
                "2024-05-17T06:07:35.176Z \u001b[32minfo\u001b[39m: Writing directory src/app/core/shared/interfaces/ to template output path.",
                "2024-05-17T06:07:35.178Z \u001b[32minfo\u001b[39m: Writing directory src/app/core/shared/utils/ to template output path.",
                "2024-05-17T06:07:35.184Z \u001b[32minfo\u001b[39m: Writing file src/app/core/services/auth.service.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.189Z \u001b[32minfo\u001b[39m: Writing file src/app/core/services/base-network.service.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.196Z \u001b[32minfo\u001b[39m: Writing file src/app/core/services/http.service.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.203Z \u001b[32minfo\u001b[39m: Writing file src/app/core/services/index.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.210Z \u001b[32minfo\u001b[39m: Writing file src/app/core/services/local-storage.service.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.217Z \u001b[32minfo\u001b[39m: Writing file src/app/core/services/shared-data.service.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.224Z \u001b[32minfo\u001b[39m: Writing file src/app/core/services/toast.service.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.238Z \u001b[32minfo\u001b[39m: Writing file src/app/auth/core/services/auth.service.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.244Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/featureOne/featureOneSub/featureOneSub.component.html to template output path with mode 33206.",
                "2024-05-17T06:07:35.252Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/featureOne/featureOneSub/featureOneSub.component.scss to template output path with mode 33206.",
                "2024-05-17T06:07:35.259Z \u001b[32minfo\u001b[39m: Writing file src/app/layout/featureOne/featureOneSub/featureOneSub.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.263Z \u001b[32minfo\u001b[39m: Writing directory src/app/core/shared/dialogs/confirmation-modal/ to template output path.",
                "2024-05-17T06:07:35.280Z \u001b[32minfo\u001b[39m: Writing file src/app/core/shared/utils/copy-url.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.290Z \u001b[32minfo\u001b[39m: Writing file src/app/core/shared/utils/get-copied-value.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.295Z \u001b[32minfo\u001b[39m: Writing file src/app/core/shared/utils/index.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.302Z \u001b[32minfo\u001b[39m: Writing file src/app/core/shared/utils/json-helper.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.309Z \u001b[32minfo\u001b[39m: Writing file src/app/core/shared/utils/trim-value.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.317Z \u001b[32minfo\u001b[39m: Writing file src/app/core/shared/utils/update-input-binding-on-changes.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.327Z \u001b[32minfo\u001b[39m: Writing file src/app/core/shared/interfaces/app-types.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.340Z \u001b[32minfo\u001b[39m: Writing file src/app/core/shared/interfaces/config.model.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.347Z \u001b[32minfo\u001b[39m: Writing file src/app/core/shared/dialogs/confirmation-modal/confirmation-modal.component.html to template output path with mode 33206.",
                "2024-05-17T06:07:35.356Z \u001b[32minfo\u001b[39m: Writing file src/app/core/shared/dialogs/confirmation-modal/confirmation-modal.component.scss to template output path with mode 33206.",
                "2024-05-17T06:07:35.363Z \u001b[32minfo\u001b[39m: Writing file src/app/core/shared/dialogs/confirmation-modal/confirmation-modal.component.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.370Z \u001b[32minfo\u001b[39m: Writing file src/app/core/shared/dialogs/confirmation-modal/confirmation-modal.servicets.ts to template output path with mode 33206.",
                "2024-05-17T06:07:35.375Z \u001b[32minfo\u001b[39m: Template result written to C:\\Users\\HP\\AppData\\Local\\Temp\\dd0faddc-221f-44b5-98f7-78e41ad666ab",
                "2024-05-17T06:07:35.390Z Finished step Fetch Base"
            ],
            "publish": [
                "2024-05-17T06:07:35.391Z Beginning step Publish",
                "2024-05-17T06:07:36.986Z Error: Failed to create the User repository dsf/sdf, Repository creation failed.: {\"resource\":\"Repository\",\"code\":\"custom\",\"field\":\"name\",\"message\":\"name already exists on this account\"} - https://docs.github.com/rest/repos/repos#create-a-repository-for-the-authenticated-user\n    at createGithubRepoWithCollaboratorsAndTopics (D:\\I2L\\Official Repo\\idp\\node_modules\\@backstage\\plugin-scaffolder-backend-module-github\\src\\actions\\helpers.ts:211:11)\n    at Object.handler (D:\\I2L\\Official Repo\\idp\\node_modules\\@backstage\\plugin-scaffolder-backend-module-github\\src\\actions\\github.ts:230:23)\n    at NunjucksWorkflowRunner.executeStep (D:\\I2L\\Official Repo\\idp\\packages\\backend\\node_modules\\@backstage\\plugin-scaffolder-backend\\src\\scaffolder\\tasks\\NunjucksWorkflowRunner.ts:344:9)\n    at NunjucksWorkflowRunner.execute (D:\\I2L\\Official Repo\\idp\\packages\\backend\\node_modules\\@backstage\\plugin-scaffolder-backend\\src\\scaffolder\\tasks\\NunjucksWorkflowRunner.ts:432:9)\n    at TaskWorker.runOneTask (D:\\I2L\\Official Repo\\idp\\packages\\backend\\node_modules\\@backstage\\plugin-scaffolder-backend\\src\\scaffolder\\tasks\\TaskWorker.ts:176:26)\n    at async run (D:\\I2L\\Official Repo\\idp\\node_modules\\p-queue\\dist\\index.js:163:29)"
            ],
            "register": []
        }
    }

    return <TaskLogStream logs={taskStream?.logs} />

};

