import React from 'react';
import { Link } from '@internal/core-components';
import { Grid, Box, Button, Avatar, TextField, Typography, Container, Paper, makeStyles, Theme } from '@material-ui/core';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CssBaseline, IconButton, InputAdornment } from '@material-ui/core';
import { SignUpPhone } from './SignUpPhone';
import OTPInput, { ResendOTP } from "otp-input-react";
import { useApi, alertApiRef, discoveryApiRef } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="secondary" align="center" {...props}>
            {'Copyright © '}
            {/* <Link color="inherit" href="https://mui.com/"> */}
            <strong>
                Idea to Life
            </strong>
            {/* </Link> */}
            {' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

export interface SingupState {
    firstName: string,
    firstNameTextUsername: string,
    firstNameErrorOpen: boolean,
    lastName: string,
    lastNameTextUsername: string,
    lastNameErrorOpen: boolean,
    email: string,
    emailTextUsername: string,
    emailErrorOpen: boolean,
    password: string,
    passwordTextUsername: string,
    passwordErrorOpen: boolean,
    passwordConfirm: string,
    passwordConfirmTextUsername: string,
    passwordConfirmErrorOpen: boolean,
    phoneNumber?: string
    hidePassword: boolean,

}

export function SignUp({ provider }: any) {
    const alertApi = useApi(alertApiRef);
    const discoveryApi = useApi(discoveryApiRef);

    const [state, setState] = React.useState<SingupState>({
        firstName: "",
        firstNameTextUsername: "",
        firstNameErrorOpen: false,
        lastName: "",
        lastNameTextUsername: "",
        lastNameErrorOpen: false,
        email: "",
        emailTextUsername: "",
        emailErrorOpen: false,
        password: "",
        passwordTextUsername: "",
        passwordErrorOpen: false,
        passwordConfirm: "",
        passwordConfirmTextUsername: "",
        passwordConfirmErrorOpen: false,
        hidePassword: true,
        phoneNumber: ""
    });

    const [isFormValid, setIsFormValid] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSignUp, setIsSignUp] = React.useState(false)
    const navigate = useNavigate();
    const handleState = (e: any) => {
        setState((pre) => ({ ...pre, [e.name]: e.value }));

        if (e.name === 'firstName' || e.name === 'lastName') {
            setState((pre) => ({
                ...pre,
                [`${e.name}TextUsername`]: e.value.length >= 4 ? '' : `${[e.name === 'firstName' ? 'First name' : 'Last name']} is required`,
                [`${e.name}ErrorOpen`]: e.value.length >= 4 ? false : true
            }))
        }

        if (e.name === 'password') {
            const rePasswordCheck = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
            setState((pre) => ({
                ...pre,
                [`${e.name}TextUsername`]: rePasswordCheck.test(e.value) ? '' : 'Your password should contain at least one upper-letter, digit, special character and 8 digits',
                [`${e.name}ErrorOpen`]: rePasswordCheck.test(e.value) ? false : true,
                passwordConfirmTextUsername: pre.password === e.value && pre.password.length >= 0 ? '' : 'Password and confirm password should be matched',
                passwordConfirmErrorOpen: pre.password === e.value && pre.password.length >= 0 ? false : true
            }))
        }

        if (e.name === 'passwordConfirm') {
            setState((pre) => ({
                ...pre,
                passwordConfirmTextUsername: pre.password === e.value ? '' : 'Password and confirm password should be matched8',
                [`${e.name}ErrorOpen`]: pre.password === e.value ? false : true
            }))
        }

        if (e.name === 'email') {
            const reValidEmail = /\S+@\S+\.\S+/;
            setState((pre) => ({
                ...pre,
                [`${e.name}TextUsername`]: reValidEmail.test(e.value) ? '' : 'Enter a valid email address',
                [`${e.name}ErrorOpen`]: reValidEmail.test(e.value) ? false : true
            }))
        }
    };

    React.useEffect(() => {
        if ((!state.firstNameErrorOpen && !state.lastNameErrorOpen && !state.emailErrorOpen && !state.passwordErrorOpen && !state.passwordConfirmErrorOpen)
            && (!!state.firstName && !!state.lastName && !!state.email && !!state.password && !!state.passwordConfirm)
            && (state.password === state.passwordConfirm)
        ) {
            setIsFormValid(false);
        }
        else {
            setIsFormValid(true);
        }
    }, [state])

    const singUpApiService = async (bodyObj: any) => {
        const body = JSON.stringify(bodyObj);
        const baseUrl = await discoveryApi.getBaseUrl('')
        const response = await fetch(`${baseUrl}${provider}/signup`,
            {
                headers: {
                    'content-type': 'application/json; charset=utf-8',
                },
                method: "POST", body: body
            });
        if (!response.ok) {
            throw new Error('Data could not be fetched!')
        } else {
            return response.json()
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!isFormValid) {
            const data = {
                email: state.email,
                password: state.password,
                firstName: state.firstName,
                lastName: state.lastName,
                phoneNumber: state.phoneNumber,
                active: true
            };
            if (state.phoneNumber && state.phoneNumber?.length < 5) {
                delete data.phoneNumber
            }
            singUpApiService(data).then((res: any) => {
                setIsLoading(true)
                if (res?.status === 200) {
                    alertApi.post({
                        message: `${res.body}`,
                        severity: 'success',
                    });
                    setIsSignUp(true);
                }

                if (res?.status === 400) {
                    if (res?.body?.includes("the given email already exists")) {
                        alertApi.post({
                            message: `${res.body}`,
                            severity: 'info',
                        });
                        setIsSignUp(true);
                    }
                    else {
                        alertApi.post({
                            message: `${res.body}`,
                            severity: 'info',
                        });
                        setIsSignUp(true);
                    }
                }

            }).catch((e) => {
                alertApi.post({
                    message: `${e.body}`,
                    severity: 'error',
                });

            }).finally(() => {
                setIsLoading(false)
            })
        }
    };


    const reSendOtpService = async () => {
        const body = JSON.stringify({ "email": state.email });
        const baseUrl = await discoveryApi.getBaseUrl('')
        const response = await fetch(`${baseUrl}${provider}/resend-verify-email-code`,
            {
                headers: {
                    'content-type': 'application/json; charset=utf-8',
                },
                method: "POST",
                body: body
            });
        if (!response.ok) {
            throw new Error('Data could not be fetched!')
        } else {
            return response.json()
        }
    };

    const handleResendOtp = () => {
        reSendOtpService().then((e) => {
            setIsLoading(true)
            if (e?.status === 200) {
                alertApi.post({
                    message: `${e.body}`,
                    severity: 'success',
                });
            }

            if (e?.status === 400) {
                alertApi.post({
                    message: `${e.body}`,
                    severity: 'info',
                });
            }
        }).catch((e) => {
            console.log(e)
            alertApi.post({
                message: `${e.body}`,
                severity: 'error',
            });
        }).catch(() => {
            setIsLoading(false)
        })
    }
    const [OTP, setOTP] = React.useState("");

    const renderButton = (buttonProps: any) => {
        return (
            <Button {...buttonProps} variant="contained" sx={{ width: '75%', margin: 'auto' }} onClick={() => handleResendOtp()}>
                {buttonProps.remainingTime !== 0 ? `Please wait for ${buttonProps.remainingTime} sec` : "Resend"}
            </Button >
        );
    };


    const renderTime = () => React.Fragment;

    const verifyOTPService = async (e: string) => {
        const body = JSON.stringify({ "email": state.email, otp: e });
        const baseUrl = await discoveryApi.getBaseUrl('')
        const response = await fetch(`${baseUrl}${provider}/verify/signup`,
            {
                headers: {
                    'content-type': 'application/json; charset=utf-8',
                },
                method: "POST",
                body: body
            });
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        } else {
            return response.json()
        }
    };
    const OtpUpdateValue = (e: string) => {
        setOTP(e);
        setIsLoading(true)
        if (e.length === 6) {
            verifyOTPService(e).then((res: any) => {
                setIsLoading(false)
                if (res.status === 200) {
                    alertApi.post({
                        message: `${res.body}`,
                        severity: 'success',
                    });
                    navigate('/login');
                } else
                    if (res.status === 400) {
                        alertApi.post({
                            message: `${res.body}`,
                            severity: 'error',
                        });
                    }
            }).catch((e) => {
                console.log(e)
                setIsLoading(false)
            })
        }
    }

    const useStyles = makeStyles((theme: Theme) => ({
        container: {
            position: 'relative',
            borderRadius: 8,
            background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            "&:before": {
                content: '""',
                position: 'absolute',
                background: theme.palette.grey[50],
                top: 1,
                bottom: 1,
                left: 1,
                right: 1,
                borderRadius: 8,
            },
        },

    }));
    const classes = useStyles();
    return (
        <Container maxWidth="xs" className={classes.container} component={Paper} elevation={6}>

            <CssBaseline />
            <Box style={{
                position: 'relative',
                padding: '24px 0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // "&.MuiOutlinedInput-root.Mui-disabled": { "& > fieldset": { border: '1px solid green' } }
            }}>

                <Avatar>
                    {/* <LockOutlinedIcon /> */}
                </Avatar>

                <Typography style={{ marginTop: '10px' }} component="h1" variant="h5">
                    Sign up
                </Typography>

                {!isSignUp &&

                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={isLoading}
                                    value={state.firstName}
                                    error={state.firstNameErrorOpen}
                                    helperText={state.firstNameTextUsername}
                                    onChange={(e) => handleState(e.target)}
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                    InputLabelProps={{
                                        // sx: { "&.Mui-focused": { color: "white" } },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={isLoading}
                                    value={state.lastName}
                                    error={state.lastNameErrorOpen}
                                    helperText={state.lastNameTextUsername}
                                    onChange={(e) => handleState(e.target)}
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="off"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    disabled={isLoading}
                                    value={state.email}
                                    error={state.emailErrorOpen}
                                    helperText={state.emailTextUsername}
                                    onChange={(e) => handleState(e.target)}
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={isLoading}
                                    onChange={(e) => handleState(e.target)}
                                    name="password"
                                    label="Password"
                                    value={state.password}
                                    error={state.passwordErrorOpen}
                                    helperText={state.passwordTextUsername}
                                    type={state.hidePassword ? "password" : "input"}
                                    id="password"
                                    autoComplete="password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setState((e) => ({ ...e, 'hidePassword': !e.hidePassword }))}
                                                    edge="end"
                                                >
                                                    {state.hidePassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={isLoading}
                                    onChange={(e) => handleState(e.target)}
                                    name="passwordConfirm"
                                    label="Confirm Password"
                                    value={state.passwordConfirm}
                                    error={state.passwordConfirmErrorOpen}
                                    helperText={state.passwordConfirmTextUsername}
                                    type={state.hidePassword ? "password" : "input"}
                                    id="passwordConfirm"
                                    autoComplete="passwordConfirm"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={isLoading}
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setState((e) => ({ ...e, 'hidePassword': !e.hidePassword }))}
                                                    edge="end"
                                                >
                                                    {state.hidePassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <SignUpPhone value={state && state?.phoneNumber ? state?.phoneNumber : ''} sx={{ mt: 2, }}
                                    disabled={isLoading}
                                    onChange={(e) => handleState({ name: 'phoneNumber', value: e })} />
                            </Grid>

                        </Grid>
                        <Box sx={{ mt: 3, mb: 2 }}>
                            <Button
                                type="submit"
                                fullWidth
                                disabled={isFormValid || isLoading}
                                variant="contained"
                            >
                                Sign Up
                            </Button>
                        </Box>
                    </Box>
                }

                {isSignUp &&

                    <Grid item xs={12} sm={12} >

                        <Typography component="p" style={{ textAlign: 'center', marginTop: 2, marginBottom: 2 }} variant="body2">
                            An otp is sent on email <strong>{state.email} </strong>  please check and enter the code below.
                        </Typography>

                        <Box style={{ marginTop: 3, marginBottom: 3 }}>
                            <OTPInput
                                inputStyles={{ width: '100%' }}
                                value={OTP}
                                onChange={OtpUpdateValue}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                disabled={isLoading} />
                        </Box>

                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                            <ResendOTP
                                renderButton={renderButton}
                                disabled={isLoading}
                                onResendClick={() => console.log("Resend clicked")}
                                renderTime={renderTime} />
                        </Box>

                    </Grid>
                }


                <Box style={{ marginTop: 2 }}>


                    <Typography component="p" variant="body2">
                        Already have an account? <Link to="/login" color='primary' style={{ marginLeft: 4 }}>
                            Sign in
                        </Link>
                    </Typography>
                    <Box style={{ marginTop: 2 }}>
                        <Copyright style={{ marginTop: 3 }} />
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}