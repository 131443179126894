import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Link } from '@internal/core-components';

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, TextField, FormControlLabel, Grid, Checkbox, Typography, IconButton, InputAdornment, Container, CssBaseline, Paper, Box, makeStyles } from '@material-ui/core';

function Copyright() {
  return (
    <Typography variant="body2" color="secondary" align="center">
      {'Copyright © '}
      {/* <Link color="inherit" href="https://mui.com/"> */}
      <strong>
        Idea to Life
      </strong>
      {/* </Link> */}
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};
export interface SingupState {
  email: string,
  emailTextUsername: string,
  emailErrorOpen: boolean,
  password: string,
  passwordTextUsername: string,
  passwordErrorOpen: boolean,
  hidePassword: boolean,
}

export function LoginForm({ onSubmit, disabled }:any) {


  const [state, setState] = React.useState<SingupState>({
    email: "",
    emailTextUsername: "",
    emailErrorOpen: false,
    password: "",
    passwordTextUsername: "",
    passwordErrorOpen: false,
    hidePassword: true,
  });


  const handleState = (e: any) => {

    setState((pre) => ({ ...pre, [e.name]: e.value }));

    if (e.name === 'password') {
      const rePasswordCheck = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      setState((pre) => ({
        ...pre,
        [`${e.name}TextUsername`]: rePasswordCheck.test(e.value) ? '' : 'Your password should be minimum 8 characters includes upper-letter, digit and special character',
        [`${e.name}ErrorOpen`]: rePasswordCheck.test(e.value) ? false : true,
        passwordConfirmTextUsername: pre.password === e.value && pre.password.length >= 0 ? '' : 'Password and confirm password should be matched',
        passwordConfirmErrorOpen: pre.password === e.value && pre.password.length >= 0 ? false : true
      }))
    }

    if (e.name === 'email') {
      const reValidEmail = /\S+@\S+\.\S+/;
      setState((pre) => ({
        ...pre,
        [`${e.name}TextUsername`]: reValidEmail.test(e.value) ? '' : 'Enter a valid email address',
        [`${e.name}ErrorOpen`]: reValidEmail.test(e.value) ? false : true
      }))
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (state.emailErrorOpen === false && state.passwordErrorOpen === false) {
      onSubmit(state.email, state.password)
    }
  };
  const useStyles = makeStyles((theme: any) => ({
    container: {
      position: 'relative',
      borderRadius: 8,
      background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      "&:before": {
        content: '""',
        position: 'absolute',
        background: theme.palette.grey[1100],
        top: 1,
        bottom: 1,
        left: 1,
        right: 1,
        borderRadius: 8,
      },
    },

  }));
  const classes = useStyles();
  return (
    <Container maxWidth="xs" className={classes.container}  component={Paper} elevation={6} >
      <CssBaseline />
      <Box
        sx={{
          position:'relative',
          py: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar>
          {/* <LockOutlinedIcon /> */}
        </Avatar>
        <Typography style={{ marginTop: '10px' }}  component="h1" variant="h5">
          Sign In
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <TextField
                variant="outlined"
                required
                autoFocus
                fullWidth
                id="email"
                disabled={disabled}
                value={state.email}
                error={state.emailErrorOpen}
                helperText={state.emailTextUsername}
                onChange={(e) => handleState(e.target)}
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>

            <Grid item xs={12} >
              <TextField
                variant="outlined"
                required
                fullWidth
                disabled={disabled}
                onChange={(e) => handleState(e.target)}
                name="password"
                label="Password"
                value={state.password}
                error={state.passwordErrorOpen}
                helperText={state.passwordTextUsername}
                type={state.hidePassword ? "password" : "input"}
                id="password"
                autoComplete="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setState((e) => ({ ...e, 'hidePassword': !e.hidePassword }))}
                        edge="end"
                      >
                        {state.hidePassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            style={{ marginTop: '10px' }}
            fullWidth
            variant="contained"
            disabled={disabled || !state.email || !state.password || state.emailErrorOpen === true || state.passwordErrorOpen === true}
          >
            Sign In
          </Button>
        </Box>

        <Box sx={{ my: 3, display: 'flex', width: '100%', justifyContent: 'space-between' }} >

          <Typography component="p" variant="body2" >
            <Link to="/forgot" color='primary'>
              Forgot password?
            </Link>
          </Typography>

          <Typography component="p" variant="body2">
            <Link to="/signup" color='primary'>
              {"Don't have an account? Sign Up"}
            </Link>
          </Typography>
        </Box>

        <Copyright />
      </Box>
    </Container>
  );
}
