import * as React from 'react';
import { Link } from '@internal/core-components';
import { CssBaseline, IconButton, InputAdornment, Typography, Container, TextField, Paper, Grid, Avatar, Button, Box, makeStyles } from '@material-ui/core';
import { useApi, alertApiRef, discoveryApiRef } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import OTPInput, { ResendOTP } from "otp-input-react";

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {/* <Link color="inherit" href="https://mui.com/"> */}
      <strong>
        Idea to Life
      </strong>
      {/* </Link> */}
      {' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

export interface ForgotState {
  email: string,
  emailTextUsername: string,
  emailErrorOpen: boolean,
  password: string,
  passwordTextUsername: string,
  passwordErrorOpen: boolean,
  passwordConfirm: string,
  passwordConfirmTextUsername: string,
  passwordConfirmErrorOpen: boolean,
  hidePassword: boolean,
}

export const Forgot = ({ provider }: any) => {

  const navigate = useNavigate();
  const alertApi = useApi(alertApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const [state, setState] = React.useState<ForgotState>({

    email: "",
    emailTextUsername: "",
    emailErrorOpen: false,
    password: "",
    passwordTextUsername: "",
    passwordErrorOpen: false,
    passwordConfirm: "",
    passwordConfirmTextUsername: "",
    passwordConfirmErrorOpen: false,
    hidePassword: true,
  });

  const [isOtpSent, setIsOtpSent] = React.useState(false)
  const [OtpEmail, setOtpEmail] = React.useState("");
  const [OTP, setOTP] = React.useState("");
  // const [isLoading, setIsLoading] = React.useState(false);

  const handleState = (e: any) => {

    setState((pre) => ({ ...pre, [e.name]: e.value }));

    if (e.name === 'email') {
      const reValidEmail = /\S+@\S+\.\S+/;
      setState((pre) => ({
        ...pre,
        [`${e.name}TextUsername`]: reValidEmail.test(e.value) ? '' : 'Enter a valid email address',
        [`${e.name}ErrorOpen`]: reValidEmail.test(e.value) ? false : true
      }))
    }

    if (e.name === 'password') {
      const rePasswordCheck = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      setState((pre) => ({
        ...pre,
        [`${e.name}TextUsername`]: rePasswordCheck.test(e.value) ? '' : 'Your password should be minimum 8 characters includes upper-letter, digit and special character',
        [`${e.name}ErrorOpen`]: rePasswordCheck.test(e.value) ? false : true,
        passwordConfirmTextUsername: pre.password === e.value && pre.password.length >= 0 ? '' : 'Password and confirm password should be matched',
        passwordConfirmErrorOpen: pre.password === e.value && pre.password.length >= 0 ? false : true
      }))
    }

    if (e.name === 'passwordConfirm') {
      setState((pre) => ({
        ...pre,
        passwordConfirmTextUsername: pre.password === e.value ? '' : 'Password and confirm password should be matched8',
        [`${e.name}ErrorOpen`]: pre.password === e.value ? false : true
      }))
    }
  };

  const forgotApiService = async () => {

    const body = JSON.stringify({ email: state.email });
    const baseUrl = await discoveryApi.getBaseUrl('')
    const response = await fetch(`${baseUrl}${provider}/reset-password`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
        },
        method: "POST", body: body
      });
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!state.emailErrorOpen) {
      forgotApiService().then((e) => {
        // setIsLoading(true)
        if (e?.status === 200) {
          if (e?.body.includes('Confirmation code is sent')) {
            setOtpEmail(state.email)
            alertApi.post({
              message: `${e.body}. Enter the code to reset the password`,
              severity: 'success',
            });
            setIsOtpSent(true)
          }
        }
        if (e?.status === 400) {
          if (e.body.includes("the given email already exists")) {
            alertApi.post({
              message: `${e.body}`,
              severity: 'info',
            });
            navigate('/login')
          }
          else {
            alertApi.post({
              message: `${e.body}`,
              severity: 'info',
            });
          }
        }

      }).catch((e) => {
        alertApi.post({
          message: `${e.body}`,
          severity: 'error',
        });
      }).finally(() => {
        // setIsLoading(false)
      })
    }
  };


  const UpdatePassword = async () => {
    const body = JSON.stringify({ "email": state.email, password: state.password, token: OTP });
    const baseUrl = await discoveryApi.getBaseUrl('');
    const response = await fetch(`${baseUrl}${provider}/update-password`,
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
        },
        method: "POST",
        body: body
      });
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  };

  const handleSubmitResetPassword = (e: any) => {
    e.preventDefault();
    // setIsLoading(true);
    UpdatePassword().then((e) => {
      if (e.status === 200) {
        alertApi.post({
          message: `${e.body}`,
          severity: 'success',
        });
        navigate('/login')
      }
      if (e.status === 400) {
        alertApi.post({
          message: `${e.body}`,
          severity: 'error',
        });
      }
    }).catch((e) => {
      console.log(e)
    }).finally(() => {
      // setIsLoading(false)
    })
  }

  const handleResendOtp = () => {
    // setIsLoading(true);
    forgotApiService().then((e) => {
      if (e?.status === 200) {
        alertApi.post({
          message: `${e.body}`,
          severity: 'success',
        });
      }

      if (e?.status === 400) {
        alertApi.post({
          message: `${e.body}`,
          severity: 'info',
        });
      }
    }).catch((e) => {
      console.log(e)
      alertApi.post({
        message: `${e.body}`,
        severity: 'error',
      });
    }).finally(() => {
      // setIsLoading(false)
    })
  }
  const renderButton = (buttonProps: any) => {
    return (
      <Button {...buttonProps} variant="contained" sx={{ width: '75%', px: 3, py: 1, margin: 'auto' }} onClick={() => handleResendOtp()}>
        {buttonProps?.remainingTime !== 0 ? `Please wait for ${buttonProps?.remainingTime} sec` : "Resend"}
      </Button >
    );
  };

  const renderTime = () => React.Fragment;

  const [IsResetFormValid, setIsResetFormValid] = React.useState(false)

  React.useEffect(() => {

    if ((!state.emailErrorOpen && !state.passwordErrorOpen && !state.passwordConfirmErrorOpen) &&
      (!!state.email && !!state.password && !!state.passwordConfirm)
      && (state.password === state.passwordConfirm) && OTP.length === 6) {
      setIsResetFormValid(false);
    }
    else {
      setIsResetFormValid(true);
    }
  }, [state])
  const useStyles = makeStyles((theme: any) => ({
    container: {

      position: 'relative',
      // padding: '0',
      borderRadius: 8,
      background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      "&:before": {
        content: '""',
        position: 'absolute',
        background: theme.palette.grey[1100],
        top: 1,
        bottom: 1,
        left: 1,
        right: 1,
        borderRadius: 8,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Container maxWidth="xs" className={classes.container} component={Paper} elevation={6}>
      <CssBaseline />

      {!isOtpSent &&
        <Box sx={{
          position:'relative',
          py: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>

          <Avatar style={{ marginBottom: 10}}>
            {/* <LockOutlinedIcon /> */}
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ my: 3, width: '100%' }}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  value={state.email}
                  error={state.emailErrorOpen}
                  helperText={state.emailTextUsername}
                  onChange={(e) => handleState(e.target)}
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 3, mb: 2 }}>
              <Button
                type="submit"
                fullWidth
                disabled={state.emailErrorOpen || !state.email}
                variant="contained"

              >
                Forgot Password
              </Button>
            </Box>
            <Box sx={{ mt: 2 }}>

              <Typography component="p" variant="body2">
                Already have an account?
                <Link to="/login" style={{ marginLeft: 4 }}>
                  Sign in
                </Link>
              </Typography>

            </Box>

          </Box>
          <Copyright sx={{ mt: 1 }} />
        </Box>
      }

      {isOtpSent &&
        <Box sx={{
          position:'relative',
          py: 3,
          textAlign: 'center'
        }}>

          <Typography component="h1" variant="h5" >
            Forgot Password
          </Typography>

          <Box component="form" onSubmit={handleSubmitResetPassword} sx={{ my: 3, width: '100%' }}>
            <Box sx={{ textAlign: 'center', pb: 3 }}>
              {OtpEmail &&
                <Typography component="p" variant="body2" >
                  An otp is sent on email
                  <br />
                  <strong>{OtpEmail} </strong>
                  <br />
                  please check and enter the code below.
                </Typography>
              }
            </Box>

            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  autoFocus
                  fullWidth
                  id="email"
                  value={state.email}
                  error={state.emailErrorOpen}
                  helperText={state.emailTextUsername}
                  onChange={(e) => handleState(e.target)}
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  onChange={(e) => handleState(e.target)}
                  name="password"
                  label="Password"
                  value={state.password}
                  error={state.passwordErrorOpen}
                  helperText={state.passwordTextUsername}
                  type={state.hidePassword ? "password" : "input"}
                  id="password"
                  autoComplete="password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setState((e) => ({ ...e, 'hidePassword': !e.hidePassword }))}
                          edge="end"
                        >
                          {state.hidePassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  onChange={(e) => handleState(e.target)}
                  name="passwordConfirm"
                  label="Confirm Password"
                  value={state.passwordConfirm}
                  error={state.passwordConfirmErrorOpen}
                  helperText={state.passwordConfirmTextUsername}
                  type={state.hidePassword ? "password" : "input"}
                  id="passwordConfirm"
                  autoComplete="passwordConfirm"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setState((e) => ({ ...e, 'hidePassword': !e.hidePassword }))}
                          edge="end"
                        >
                          {state.hidePassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} style={{ marginLeft: 2 }}>
                <OTPInput
                  inputStyles={{ width: '100%' }}
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false} />
              </Grid>



              {OTP.length !== 6 &&
                <Box sx={{ my: 3, mx: 'auto' }}>
                  <ResendOTP
                    renderButton={renderButton}
                    style={{ width: "100%" }}
                    onResendClick={() => console.log("Resend clicked")}
                    renderTime={renderTime} />
                </Box>
              }

              {OTP.length === 6 &&
                <Box sx={{ my: 3, mx: 'auto' }}>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={IsResetFormValid}
                    variant="contained"
                    style={{ marginLeft: 2, marginTop: 3, marginBottom: 2 }}
                  >
                    Reset Password
                  </Button>
                </Box>
              }
            </Grid>

            <Grid container justifyContent="center">
              <Grid item>
                <Typography component="p" variant="body2">Already have an account?
                  <Link to="/login" color='primary' style={{ marginLeft: 4 }}>
                    Sign in
                  </Link>
                </Typography>
              </Grid>
            </Grid>

          </Box>

          <Copyright sx={{ mt: 1 }} />
        </Box>
      }

    </Container>

  );
}