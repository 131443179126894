/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { JsonObject } from '@backstage/types';

export function sortKeys(data: JsonObject): JsonObject {
  // we could do something custom, but lexicographical sorting is actually a
  // good choice at least for the default set of keys
  return Object.fromEntries(
    [...Object.entries(data)].sort((a, b) => (a[0] < b[0] ? -1 : 1)),
  );
}
