/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Entity } from '@backstage/catalog-model';
import { CodeSnippet } from '@internal/core-components';
import DialogContentText from '@material-ui/core/DialogContentText';
import React from 'react';
import YAML from 'yaml';
import { sortKeys } from './util';
import { Container } from './common';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

export function YamlPage(props: { entity: Entity, onCloseModal: any }) {
  return (
    <>
      <DialogContentText variant="h4">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          Raw YAML
          <IconButton
            aria-label="close"
            onClick={props?.onCloseModal()}
          >
            <CloseIcon />
          </IconButton>
        </Box>

      </DialogContentText>


      <Container title="Entity as YAML">
        <DialogContentText>
          This is the raw entity data as received from the catalog, on YAML form.
        </DialogContentText>
        <DialogContentText>
          <div style={{ fontSize: '75%' }} data-testid="code-snippet">
            <CodeSnippet
              customStyle={{ background: 'transparent' }}
              text={YAML.stringify(sortKeys(props.entity))}
              language="yaml"
              showCopyCodeButton
            />
          </div>
        </DialogContentText>
      </Container>
    </>
  );
}
