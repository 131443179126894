import { discoveryApiRef, SignInPageProps, useApi } from '@backstage/core-plugin-api';
import React, { useState, useEffect } from 'react';
import { useAsync } from '@react-hookz/web';
import { IdentityProviders, Progress } from '@internal/core-components';
import { SignInIdentity } from '../Identity';
import { LoginForm } from './Form';
import { useNavigate } from 'react-router-dom';

export type ISignInPageProps = SignInPageProps & {
    provider: string;
    children?: React.ReactNode | null;
    providers: IdentityProviders;
};

export const Login = (props: ISignInPageProps ) => {
    const discoveryApi = useApi(discoveryApiRef);
    const navigate = useNavigate();
    const [email, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [identity] = useState(
        new SignInIdentity({
            provider: props.provider,
            discoveryApi,
        })
    );
    
    const [{ status }, { execute }] = useAsync(async () => {
        await identity.login({ email, password });
        navigate('/');
        props.onSignInSuccess(identity);
    });

    const [{ status: statusRefresh }, ] = useAsync(
        async () => {
            await identity.fetch();
            props.onSignInSuccess(identity);
        }

    );

    useEffect(() => {
    }, []);
    
    function onSubmit(u: string, p: string) {
        setUsername(u);
        setPassword(p);
        setTimeout(execute, 0);
    }

    return (
        <>
            {status === 'loading' || statusRefresh === 'loading' && <Progress />}
            <>
                {props.children}
                <LoginForm onSubmit={onSubmit} disabled={status === 'loading' || statusRefresh === 'loading'} />
            </>

        </>
    )
};
