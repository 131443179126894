import React from "react";
import { Grid, Paper, CssBaseline, makeStyles, createStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEntity, entityRouteRef } from "@internal/plugin-catalog-react-custom";
import { Progress } from '@internal/core-components';
import SearchBar from "material-ui-search-bar";
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import { useRouteRefParams } from "@backstage/core-plugin-api";
import not_found from '../../../assets/404/404_Not_found.jpg';
import { Link } from '@internal/core-components';
import LoopIcon from '@material-ui/icons/Loop';
import CircularProgress from '@mui/material/CircularProgress';

export interface IRepoStatus {
  status: 'pending' | 'completed' | 'failed' | "inprogress" | "loading"
}
export interface ICodeSnippets {
  "summary": string
  "content"?: string
  "isShow"?: boolean
  "index": number
  "language"?: string
  "source"?: string
  "metadata"?: string
}
export const useStyles = makeStyles((theme: any) =>
  createStyles({
    rotateLoopIcon: {
      animation: "$spin 2s linear infinite"
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)"
      },
      "100%": {
        transform: "rotate(0deg)"
      }
    },
    codeBlockWrapper: {

      padding: '10px',
      listStyle: 'none',
      position: 'relative',
      borderRadius: 10,
      zIndex: 0,
      background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      "&:before": {
        content: '""',
        position: 'absolute',
        background: theme.palette.grey[1100],
        top: 2,
        bottom: 2,
        left: 2,
        zIndex: -1,
        right: 2,
        borderRadius: 10,
      },
    }
  })
);

export const SearchRepository = () => {
  const { entity } = useEntity();
  const { name } = useRouteRefParams(entityRouteRef);
  const [repoStatus, setRepoStatus] = React.useState<IRepoStatus>({ status: 'loading' })
  const [searchState, setSearchState] = React.useState<string>('');
  const [repoUid] = React.useState<string>(`${(name?.replaceAll(/[\W_]/g, "_") + '_' + entity?.metadata?.uid)?.slice(0, 18).replaceAll('-', '_')}`);
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [codeSnippet, setCodeSnippet] = React.useState<ICodeSnippets[]>([]);
  const [searchError, setSearchError] = React.useState<string>('');
  const [resSnippetsNotFound, setResSnippetsNotFound] = React.useState<boolean>(false);
  const [intervalRepoStatusFlag, setIntervalRepoStatusFlag] = React.useState<boolean>(false);
  const [numberOfResults, setNumberOfResults] = React.useState<number>(0)
  const classes = useStyles();
  const urlTextReplace = entity?.metadata?.annotations ? entity?.metadata?.annotations["backstage.io/source-location"]?.replace('url:', '') : null;
  const taskStatusService = async (ex: any) => {
    const response = await fetch(`https://fpt-document-search.onexstudio.ai/task-status/${ex}`,
      {
        method: "GET",
        headers: {
          'content-type': 'application/json; charset=utf-8',
        }
      });
    return response.json();
  };

  const process_repo_code = async (e: string) => {
    const data = JSON.stringify(
      {
        "repo_code": `${repoUid}`,
        "query": `${e}`
      }
    )
    const response = await fetch('https://fpt-document-search.onexstudio.ai/process_repo_code/',
      {
        headers: {
          'content-type': 'application/json; charset=utf-8',
        },
        method: "POST",
        body: data
      });

    return response.json()
  }

  const taskStartService = async (isModified: boolean = true) => {

    if (!!urlTextReplace) {
      setIsLoading(true)
      const data = JSON.stringify({
        "id": `${repoUid}`,
        "repo_url":  urlTextReplace.includes('_git') ? urlTextReplace.split('?')[0] : urlTextReplace.replace('/tree/master/', '.git'),
        "user_decision": !isModified ? isModified.toString() : "true",
      });
      await fetch(`https://fpt-document-search.onexstudio.ai/start-task/`,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
          },
          method: "POST",
          body: data
        }).then(res => res.json())
        .then(e => {
          if (e.status === 'success') {
            setRepoStatus({ status: "inprogress" });
            setIntervalRepoStatusFlag(true);
          }

        }).catch(
          () => {
          }).finally(() => {
            setIsLoading(false);
          })
    }
  }

  const handleStatusOfRepo = () => {
    if (!!repoUid) {
      setResSnippetsNotFound(false);
      setIsLoading(true);
      taskStatusService(`${repoUid}`).then((e: any) => {
        if (e?.message === "U ID not found") {
          taskStartService(false);
        }
        else if (e?.message?.status === 'failed' || e?.status === "pending") {
          setRepoStatus({ status: 'loading' });
          taskStartService();
        }
        else if (e?.message?.status === "completed") {
          setRepoStatus({ status: "completed" });
        }


      }).catch(() => {
      }).finally(() => {
        setIsLoading(false);
      })
    }
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (intervalRepoStatusFlag === true) {
        handleStatusOfRepo();
        setIntervalRepoStatusFlag(false);
      }
    }, 15000)
    return () => {
      clearInterval(interval);
    };

  }, [intervalRepoStatusFlag]);

  React.useEffect(() => {
    taskStatusService(`${repoUid}`).then((e: any) => {
      if (e?.message === "U ID not found") {
        taskStartService(false);
      }
      else if (e?.status === 'failed' || e?.message?.status === 'failed' || e?.status === "pending") {
        taskStartService();
        setRepoStatus({ status: 'pending' });
      }
      else if (e === null || e.status === "inprogress") {
        setRepoStatus({ status: 'inprogress' });
        setIntervalRepoStatusFlag(true);
      }
      else {
        setRepoStatus({ status: 'completed' });
      }
    }).catch(() => {
    })

  }, [])

  const searchTheRepoCode = () => {
    if (!!searchState) {
      setCodeSnippet([]);
      setSearchError('');
      setIsLoading(true);
      setResSnippetsNotFound(false);
      process_repo_code(searchState)

        .then(e => {
          if (e.hasOwnProperty('summary')) {
            setSearchError(e.summary)
          }
          else {
            if (e?.source_documents?.length === 0 || e.status_code === 404) {
              setResSnippetsNotFound(true)
            }
            else {
              setNumberOfResults(0);
              setCodeSnippet(e.source_documents.map((res: any, i: number) => ({
                ...res,
                index: i,
                isShow: true,
                source: `${!!res?.source?.split('all_repo/')[1]?.split('/')?.slice(1)?.join('/') ? urlTextReplace + res?.source?.split('all_repo/')[1]?.split('/')?.slice(1)?.join('/') : ''}`
              })));
            }
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        })
    }
  }

  const hideResult = (e: any) => {
    if (!!e) {
      setCodeSnippet((x: ICodeSnippets[]) => x.map((y: ICodeSnippets) => ({ ...y, isShow: y.index === e.index ? false : y.isShow })));
    }
  }

  return (

    <Box component={Paper}>
      <CssBaseline />

      {repoStatus && repoStatus.status === 'loading' &&
        <Box>
          <Box sx={{ padding: 2 }}>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress />
              <Typography variant="h4" component="h6" sx={{ ml: '15px' }}>Loading...</Typography>
            </Box>

          </Box>
          {
            !isLoading &&
            <Progress />
          }
        </Box>
      }

      {repoStatus && (repoStatus.status === "pending" || repoStatus.status === "failed") &&
        <Box
          sx={{
            padding: 2,
          }}
        >   <Grid item >
            <Typography variant="h6" component="h6">Error encountered during repository cloning</Typography>
            <Typography variant="body2" component="h6" sx={{ marginTop: '15px' }}>The repository may not have been cloned successfully or encountered an error during the cloning process.</Typography>

            <Button
              type="button"
              variant="contained"
              disabled={isLoading}
              onClick={() => handleStatusOfRepo()}
              sx={{ mt: 3, mb: 2 }}>
              <LoopIcon className={isLoading ? classes.rotateLoopIcon : ''} />
            </Button>
          </Grid>
        </Box>
      }

      {repoStatus && repoStatus?.status === "inprogress" &&
        <>
          <Box sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex' }}>
              <Button
                type="button"
                disabled={repoStatus?.status === "inprogress"}
                variant="contained"
                sx={{ marginRight: '15px', borderRadius: '10px', '&:before': { borderRadius: '10px' } }}
                onClick={() => handleStatusOfRepo()}
              >
                <LoopIcon className={repoStatus.status === "inprogress" ? classes.rotateLoopIcon : ''} />
              </Button>
              <Typography variant="h4" component="h6">In Progress</Typography>
            </Box>
          </Box>
          {!isLoading && <Progress />}
        </>
      }

      {repoStatus && repoStatus.status === "completed" &&
        <Box sx={{ display: 'flex' }}>
          <Button
            type="button"
            variant="contained"
            disabled={isLoading}
            onClick={() => handleStatusOfRepo()}
            sx={{ mr: '15px', borderRadius: '10px', '&:before': { borderRadius: '10px' } }}>
            <LoopIcon
              className={isLoading ? classes.rotateLoopIcon : ''}
            />
          </Button>

          <SearchBar
            style={{
              width: '100%',
            }}
            className={classes.codeBlockWrapper}
            disabled={isLoading}
            closeIcon={<>  <Typography variant="h6" sx={{ fontSize: '12px' }} component="p"> Clear</Typography>   </>}
            placeholder="Search in ONEX"
            onChange={(e) => setSearchState(e)}
            onRequestSearch={() => searchTheRepoCode()}
            value={searchState}
          />
        </Box>
      }
      {
        isLoading &&
        <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Progress />
        </Box>
      }
      {!!searchError && <>
        <Typography className={classes.codeBlockWrapper} variant="h6" sx={{ marginTop: '14px', padding: '50px!important' }} component="h2"> {searchError}</Typography>
      </>
      }
      {codeSnippet && codeSnippet.length > 0 && codeSnippet.map((x: any, i: number) =>
      (
        numberOfResults >= i && x.isShow &&
        <>
          <Box sx={{ textAlign: 'right', marginTop: '15px', marginBottom: '15px', }}>
            {!!x.source &&
              <Link to={x.source} variant="body2">
                View in Repository
              </Link>
            }
          </Box>
          <Box className={classes.codeBlockWrapper}>
            <CopyBlock
              language={x.language}
              text={`${x.content}`}
              codeBlock
              theme={atomOneDark}
            />
            <Box>
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {x.summary}
              </pre>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center', marginTop: 2 }}>

            <Button variant="contained" onClick={() => hideResult(x)} sx={{ borderRadius: '10px', '&:before': { borderRadius: '10px' } }}>
              Hide
            </Button>
            {numberOfResults !== codeSnippet?.length - 1 && codeSnippet?.length !== 0 && numberOfResults === i &&
              <Button sx={{ marginLeft: 2, borderRadius: '10px', '&:before': { borderRadius: '10px' } }} onClick={() => setNumberOfResults((re) => re + 1)}>Show More</Button>
            }
          </Box>
        </>
      ))
      }
      {
        resSnippetsNotFound &&
        <Box sx={{ display: 'flex', justifyContent: 'center', py: '15px' }}>
          <img src={not_found} alt="404_not_found" width={'100%'} />
        </Box>
      }
    </Box >

  )
}