import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const metricGraphRepositoryPlugin = createPlugin({
  id: 'metric-graph-repository',
  routes: {
    root: rootRouteRef,
  },
});
// export const MetricGraphRepositoryPage = GraphComponent
export const MetricGraphRepositoryPage = metricGraphRepositoryPlugin.provide(
  createRoutableExtension({
    name: 'MetricGraphRepositoryPage',
    component: () =>
      import('./components/GraphLayout').then(m => m.GraphComponent),
    mountPoint: rootRouteRef,
  }),
);
// export const NodeGraphRepositoryPage = metricGraphRepositoryPlugin.provide(
//   createRoutableExtension({
//     name: 'NodeGraphRepositoryPage',
//     component: () =>
//       import('./components/NodeGraphNetwork').then(m => m.NodeGraphNetwork),
//     mountPoint: rootRouteRef,
//   }),
// );
