import {
  alertApiRef,
  BackstageUserIdentity,
  discoveryApiRef,
  IdentityApi,
  ProfileInfo,
  useApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { LoginSession } from './types';

export const DEFAULTS = {
  // The amount of time between token refreshes, if we fail to get an actual
  // value out of the exp claim
  defaultTokenExpiryMillis: 5 * 60 * 1000,
  // The amount of time before the actual expiry of the Backstage token, that we
  // shall start trying to get a new one
  tokenExpiryMarginMillis: 5 * 60 * 1000,
} as const;

// When the token expires, with some margin
export function tokenToExpiry(jwtToken: string | undefined): Date {
  const fallback = new Date(Date.now() + DEFAULTS.defaultTokenExpiryMillis);
  if (!jwtToken) {
    return fallback;
  }

  const [_header, rawPayload, _signature] = jwtToken.split('.');
  const payload = JSON.parse(atob(rawPayload));
  if (typeof payload.exp !== 'number') {
    return fallback;
  }

  return new Date(payload.exp * 1000 - DEFAULTS.tokenExpiryMarginMillis);
}

type ProxiedSignInIdentityOptions = {
  provider: string;
  discoveryApi: typeof discoveryApiRef.T;
};

type State =
  | {
      type: 'empty';
    }
  | {
      type: 'active';
      session: LoginSession;
      expiresAt: Date;
    }
  | {
      type: 'failed';
      error: Error;
    };

export type Auth = { email: string; password: string };

/**
 * An identity API that gets the user auth information solely based on a
 * provider's `/refresh` endpoint.
 */
export class SignInIdentity implements IdentityApi {
  private readonly options: ProxiedSignInIdentityOptions;
  private readonly abortController: AbortController;
  private state: State;
  private alertApi = useApi(alertApiRef);
  constructor(options: ProxiedSignInIdentityOptions) {
    this.options = options;
    this.abortController = new AbortController();
    this.state = { type: 'empty' };
  }

  async login(auth: Auth) {
    if (this.state.type === 'active' && new Date() < this.state.expiresAt) {
      return this.state.session;
    }

    const baseUrl = await this.options.discoveryApi.getBaseUrl('');

    const response = await fetch(`${baseUrl}${this.options.provider}/login`, {
      method: 'POST',
      signal: this.abortController.signal,
      headers: { 'content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify(auth),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response
      .json()
      .then(e => e.body)
      .then(session => {
        if (typeof session === 'string') {
          this.alertApi.post({
            message: `${session}`,
            severity: 'info',
          });
        }
        if (session?.status === 200) {
          this.alertApi.post({
            message: 'Login Successfully',
            severity: 'info',
          });
        }

        if (session.backstageIdentity) {
          this.state = {
            type: 'active',
            session,
            expiresAt: tokenToExpiry(session.backstageIdentity.accessToken),
          };
          return session;
        }
      })
      .catch(error => {
        console.log(error);
        this.state = {
          type: 'failed',
          error,
        };
        throw error;
      });
  }

  async fetch(forceRefresh?: boolean) {
    await this.getSessionAsync(forceRefresh);
  }

  /** {@inheritdoc @backstage/core-plugin-api#IdentityApi.getUserId} */
  getUserId(): string {
    const { backstageIdentity } = this.getSessionSync();
    const ref = backstageIdentity.identity.userEntityRef;
    const match = /^([^:/]+:)?([^:/]+\/)?([^:/]+)$/.exec(ref);
    if (!match) {
      throw new TypeError(`Invalid user entity reference "${ref}"`);
    }
    return match[3];
  }

  /** {@inheritdoc @backstage/core-plugin-api#IdentityApi.getIdToken} */
  async getIdToken(): Promise<string | undefined> {
    const session = await this.getSessionAsync();
    return session.backstageIdentity.accessToken;
  }

  /** {@inheritdoc @backstage/core-plugin-api#IdentityApi.getProfile} */
  getProfile(): ProfileInfo {
    const session = this.getSessionSync();
    return session.profile;
  }

  /** {@inheritdoc @backstage/core-plugin-api#IdentityApi.getProfileInfo} */
  async getProfileInfo(): Promise<ProfileInfo> {
    const session = await this.getSessionAsync();
    return session.profile;
  }

  /** {@inheritdoc @backstage/core-plugin-api#IdentityApi.getBackstageIdentity} */
  async getBackstageIdentity(): Promise<BackstageUserIdentity> {
    const session = await this.getSessionAsync();
    return session.backstageIdentity.identity;
  }

  /** {@inheritdoc @backstage/core-plugin-api#IdentityApi.getCredentials} */
  async getCredentials(): Promise<{ token?: string | undefined }> {
    const session = await this.getSessionAsync();
    return {
      token: session.backstageIdentity.accessToken,
    };
  }

  /** {@inheritdoc @backstage/core-plugin-api#IdentityApi.signOut} */
  async signOut(): Promise<void> {
    this.abortController.abort();
    if (this.state.type !== 'empty' && this.state.type !== 'failed') {
      const email = await this.state?.session?.profile?.email;
      const baseUrl = await this.options?.discoveryApi.getBaseUrl('');
      await fetch(`${baseUrl}${this.options.provider}/logout`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email }),
      });
    }
  }

  getSessionSync(): LoginSession {
    if (this.state.type === 'active') {
      return this.state.session;
    }
    throw new Error('No session available. Try reloading your browser page.');
  }

  async getSessionAsync(forceRefresh?: boolean): Promise<LoginSession> {
    if (
      this.state.type === 'active' &&
      new Date() < this.state.expiresAt &&
      !forceRefresh
    ) {
      return this.state.session;
    }

    const promise = this.fetchSession().then(
      session => {
        this.state = {
          type: 'active',
          session,
          expiresAt: tokenToExpiry(session.backstageIdentity.accessToken),
        };
        return session;
      },
      error => {
        this.state = {
          type: 'failed',
          error,
        };
        throw error;
      },
    );

    return promise;
  }

  // async loginAsync(auth: Auth): Promise<LoginSession> {

  //     if (this.state.type === 'active' && new Date() < this.state.expiresAt) {
  //         return this.state.session;
  //     }

  //     const baseUrl = await this.options.discoveryApi.getBaseUrl('');

  //     const response = await fetch(`${baseUrl}${this.options.provider}/login`,
  //         {
  //             method: 'POST',
  //             signal: this.abortController.signal,
  //             headers: { 'content-Type': 'application/json; charset=utf-8' },
  //             body: JSON.stringify(auth),
  //         }
  //     )

  //     if (!response.ok) {
  //         throw await ResponseError.fromResponse(response);
  //     }

  //     return await response.json().then(
  //         (session) => {
  //             this.state = {
  //                 type: 'active',
  //                 session: session.body,
  //                 expiresAt: tokenToExpiry(session.backstageIdentity.accessToken),
  //             };
  //             return session.body;
  //         }

  //     ).catch((error) => {
  //         this.state = {
  //             type: 'failed',
  //             error,
  //         };
  //         throw error;
  //     })
  // }

  async fetchSession(): Promise<LoginSession> {
    const baseUrl = await this.options.discoveryApi.getBaseUrl('');
    const response = await fetch(`${baseUrl}${this.options.provider}/login`, {
      method: 'POST',
      signal: this.abortController.signal,
      headers: { 'content-Type': 'application/json; charset=utf-8' },
      // body: JSON.stringify({email: this.state.session?.profile?.email, password: this.state.session?.profile?.password}),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }
}
